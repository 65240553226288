<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户名" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="真实姓名">
                    <el-input v-model="query.realName" placeholder="请输入真实姓名" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model="query.idCard" placeholder="请输入身份证号" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="query.gender" placeholder="请选择性别">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="男" :value="1"></el-option>
						<el-option label="女" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="注册时间">
					<el-date-picker v-model="registerDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
                <el-form-item label="认证时间">
					<el-date-picker v-model="createDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
                <el-form-item label="年龄范围">
                    <el-input type="number" v-model="query.minAge" style="width: 100px;" placeholder="开始年龄" @keydown.enter.native="search" />
                    -
                    <el-input type="number" v-model="query.maxAge" style="width: 100px;" placeholder="结束年龄" @keydown.enter.native="search" />
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="120" align="center"></el-table-column>
            <el-table-column prop="gender" label="性别" min-width="120" align="center">
                <template slot-scope="scope">
                    {{ {1: '男', 2: '女'}[scope.row.gender] }}
                </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" min-width="120" align="center"></el-table-column>
            <el-table-column prop="name" label="真实姓名" min-width="120" align="center"></el-table-column>
            <el-table-column prop="idCard" label="身份证号码" min-width="150" align="center"></el-table-column>
            <el-table-column prop="faceImage" label="身份证图片" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-image
                    style="width: 100px; height: 100px; padding-top: 5px;"
                    :src="scope.row.faceImage" 
                    :preview-src-list="[scope.row.faceImage]"
                    >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="faceVideo" label="人脸视频" width="180" align="center">
                <template slot-scope="scope">
                    <video v-if="scope.row.faceVideo" muted="muted" class="video" :src="scope.row.faceVideo" type="video/mp4" :poster="scope.row.faceImage" autoplay="autoplay" style="width: 150px; height: 150px; padding-top: 10px;" controls="controls" loop="-1">
                        <p>你的浏览器不支持video标签.</p>
                    </video>
                </template>
            </el-table-column>
            <el-table-column prop="registerTime" label="注册时间" min-width="120" align="center"></el-table-column>
            <el-table-column prop="createTime" label="认证时间" min-width="120" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    realNamePage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                realName: "",
                idCard: "",
                gender: "",
                minAge: null,
                maxAge: null,
            },
            registerDate: [],
            createDate: [],

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

            
            formLabelWidth: "80px",
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getRealNameList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getRealNameList();
        },
        //分页获取实名认证列表
        getRealNameList() {
            realNamePage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                gender: this.query.gender,
                idCard: this.query.idCard,
                name: this.query.realName,
                minAge: this.query.minAge || '',
                maxAge: this.query.maxAge || '',
                registerStartTime: this.registerDate?.length ? this.registerDate[0] : '',
                registerEndTime: this.registerDate?.length ? this.registerDate[1] : '',
                startTime: this.createDate?.length ? this.createDate[0] : '',
                endTime: this.createDate?.length ? this.createDate[1] : ''
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let realName = this.dataList[i];
                        if (realName.faceImage != null && realName.faceImage.length > 0) {
                            realName.faceImage = "https://file.oiki.cc/" + realName.faceImage
                        }
                        if (realName.faceVideo != null && realName.faceVideo.length > 0) {
                            realName.faceVideo = "https://file.oiki.cc/" + realName.faceVideo
                        }
                    }
                })
        }
    },
    mounted() {
        this.getRealNameList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>