<template>
    <div class="page">
        <!-- <div class="crumb text-left">
            <el-link class="back" type="primary" :underline="false" @click="toUserList"><i class="el-icon-back"></i>用户管理</el-link>
             > 
            {{ userInfo.nickName }}
        </div> -->

        <div class="userinfo-box text-left" v-if="userInfo.id">
            <div class="box">
                <div class="info-title">基础信息</div>
                <div class="item">
                    <div class="label">用户昵称：</div>
                    <div class="value">{{ userInfo.nickName }}</div>
                </div>
                <div class="item">
                    <div class="label">注册手机号：</div>
                    <div class="value">{{ userInfo.phone }}</div>
                </div>
                <div class="item">
                    <div class="label">用户ID：</div>
                    <div class="value">{{ userInfo.userNumber }}</div>
                </div>
                <div class="item">
                    <div class="label">性别：</div>
                    <div class="value">{{ userInfo.gender ? userInfo.gender == 1 ? '男' : '女' : '' }}</div>
                </div>
                <div class="item">
                    <div class="label">年龄：</div>
                    <div class="value">{{ userInfo.age }}</div>
                </div>
            </div>
            <div class="box">
                <div class="info-title">身份信息</div>
                <div class="item">
                    <div class="label">是否实名：</div>
                    <div class="value">{{ userInfo.isRealName == 1 ? '是' : '否' }}</div>
                </div>
                <div class="item">
                    <div class="label">姓名：</div>
                    <div class="value">{{ userInfo?.realName?.name }}</div>
                </div>
                <div class="item">
                    <div class="label">身份证号：</div>
                    <div class="value">{{ userInfo?.realName?.idCard }}</div>
                </div>
                <div class="item">
                    <div class="label">实名照片：</div>
                    <div class="file">
                        <el-image style="width: 60px;height: 60px;" :src="userInfo?.realName?.faceImage" :previewSrcList="[userInfo?.realName?.faceImage]"></el-image>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="label">实名视频：</div>
                    <div class="value">
                        <video v-if="userInfo?.realName?.faceVideo" muted="muted" class="video" :src="userInfo?.realName?.faceVideo" type="video/mp4" :poster="userInfo?.realName?.faceImage" style="width: 60px; height: 60px;" controls="controls" loop="-1">
                            <p>你的浏览器不支持video标签.</p>
                        </video>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="info-title">注册登录信息</div>
                <div class="item">
                    <div class="label">注册时间：</div>
                    <div class="value">{{ userInfo.createTime }}</div>
                </div>
                <div class="item">
                    <div class="label">注册来源：</div>
                    <div class="value">{{ userInfo.platform }}</div>
                </div>
                <div class="item">
                    <div class="label">注册渠道：</div>
                    <div class="value">{{ userInfo.channel }}</div>
                </div>
                <div class="item">
                    <div class="label">最后登录时间：</div>
                    <div class="value">{{ userInfo.lastTime }}</div>
                </div>
                <div class="item">
                    <div class="label">版本号：</div>
                    <div class="value">{{ userInfo.appVersion }}</div>
                </div>
                <div class="item" v-if="userInfo.upUserNumber">
                    <div class="label">上级用户：</div>
                    <div class="value">
                        <a :href="'/userNumber/' + userInfo.upUserNumber" target="_blank">{{ userInfo.upUserNumber }}</a>
                    </div>
                </div>
                <div class="item">
                    <div class="label">角色：</div>
                    <div class="value">{{ userInfo.roleName }}</div>
                </div>
                <div class="item">
                    <div class="label">账号状态：</div>
                    <div class="value">
                        <el-link class="cursor-default" style="cursor: default;" :type="statusAry[userInfo.status].type" :underline="false">{{statusAry[userInfo.status].label}}</el-link>
                    </div>
                </div>
                <div class="item border-bottom" v-if="userInfo.status == 2 && userInfo.remark">
                    <div class="label">禁用理由：</div>
                    <div class="value">{{ userInfo.remark }}</div>
                </div>
                <div class="item" v-if="userInfo.status == 2 && userInfo.customerRemark">
                    <div class="label">客服备注：</div>
                    <div class="value">{{ userInfo.customerRemark }}</div>
                </div>
            </div>
            <div class="box">
                <div class="info-title">财务信息</div>
                <div class="item">
                    <div class="label">K币余额：</div>
                    <div class="value">{{ userInfo?.account?.totalCoin }}</div>
                </div>
                <div class="item">
                    <div class="label">收益余额：</div>
                    <div class="value">{{ userInfo?.account?.totalMoney }}</div>
                </div>
                <div class="item">
                    <div class="label">K币总消耗：</div>
                    <div class="value">{{ userInfo?.useCoinNumber }}</div>
                </div>
                <div class="item">
                    <div class="label">总提现金额：</div>
                    <div class="value">{{ userInfo?.totalTakeMoney }}</div>
                </div>
                <div class="item">
                    <div class="label">贵族状态：</div>
                    <div class="value">
                        <span v-if="userInfo?.isVip == 1" style="color: #409EFF;">{{ userInfo.vipName }}</span>
                        <span v-else-if="userInfo?.vipLevel" style="color: #E6A23C;">已过期</span>
                        <span v-else style="color: #909399;">普通用户</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-box text-left">
            <div class="info-title">平台资料信息</div>
            <div class="row">
                <div class="col">
                    <div class="detail-title">头像：</div>
                    <div class="detail-content">
                        <div class="flex-content" v-if="userInfo?.headImg">
                            <el-image style="width: 60px;height: 60px;" :src="userInfo?.headImg" :previewSrcList="[userInfo?.headImg]" ></el-image>
                            <el-button type="danger" size="mini" plain @click="avatarDeleteHandler" v-if="userInfo?.headImg != defaultAvatar">删除</el-button>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="detail-title">个性签名：</div>
                    <div class="detail-content pre" style="max-width: 200px;">{{ userInfo?.sign }}</div>
                    <el-button type="danger" size="mini" plain @click="deleteSign" v-if="userInfo.sign">删除</el-button>
                </div>
                <div class="col">
                    <div class="detail-title">社交人格：</div>
                    <div class="detail-content">
                        {{ userInfo?.personalityName }}
                    </div>
                </div>
                <div class="col" v-if="userInfo?.audioTime > 0">
                    <div class="detail-title">语音介绍：</div>
                    <div class="detail-content">
                        <!-- <div class="audio-box">
                            <div class="audio">
                                <el-slider v-model="audioCurrentPosition" :disabled="audio?._isPlaying" :step="0.01" :min="0" :max="parseFloat(userInfo?.audioTime)" :show-tooltip="false" @change="changeAudioPosition"></el-slider>
                            </div>
                            <div class="audio-time">{{ Math.ceil(audioCurrentPosition) }} / {{ userInfo?.audioTime }}</div>
                            <el-button size="mini" @click="playVoice">{{audio?._isPlaying ? '暂停' : '播放'}}</el-button>
                        </div> -->

                        <!-- <div class="voice" @click="playVoice">{{ userInfo?.audioTime }}S</div> -->

                        <audio v-if="userInfo.audio" controls="controls">
                            <source :src="fileDomain + userInfo.audio" :type="userInfo.audioType">
                        </audio>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col flex-items-start">
                    <div class="detail-title">相册：</div>
                    <div class="detail-content">
                        <div class="imgs" v-if="userInfo?.albums?.length">
                            <div class="img" v-for="(img,index) in userInfo.albums" :key="img">
                                <div>
                                    <el-image style="width: 60px;height: 60px;" :src="img" :previewSrcList="userInfo.albums" :initial-index="index" ></el-image>
                                </div>
                                <el-button type="danger" size="mini" plain @click="albumDeleteHandler(userInfo.albumList[index])">删除</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col flex-items-start" style="flex-grow: 1;">
                    <div class="detail-title">动态：</div>
                    <div class="detail-content w-full">
                        <el-table id="mainTable" :height="500" :data="articleList" border style="width: 100%" :cell-style="{ padding: '0' }"
                            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                            <el-table-column label="动态ID" min-width="80" align="center">
                                <template slot-scope="scope">
                                    <a :href="'/article/' + scope.row.id" target="_blank">{{ scope.row.id }}</a>
                                </template>
                            </el-table-column>
                            <el-table-column label="文字内容" min-width="180" align="center">
                                <template slot-scope="scope">
                                    <div class="content-box">
                                        <div class="content-row" v-if="scope.row.content">
                                            <div class="content">{{ scope.row.content.slice(0, 80) }}</div>
                                            <el-popover
                                                width="500"
                                                trigger="click"
                                                placement="left"
                                                :content="scope.row.content"
                                                v-if="scope.row.content.length > 80"
                                            >
                                                <div class="view-more" slot="reference">查看完整内容</div>
                                            </el-popover>
                                        </div>                                        
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="图片内容" min-width="200" align="center">
                                <template slot-scope="scope">
                                    <div class="imgs">
                                        <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                                            :preview-src-list="scope.row.images" >
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="音视频内容" min-width="200" align="center">
                                <template slot-scope="scope">
                                    <div class="video" v-if="scope.row.video">
                                        <video muted="muted" class="video" :src="scope.row.video" type="video/mp4" :poster="scope.row.Image" style="width: 120px; height: 120px; padding-top: 10px;" controls="controls" loop="-1">
                                            <p>你的浏览器不支持video标签.</p>
                                        </video>
                                    </div>
                                    <div class="audio" v-else-if="scope.row.audio">
                                        <audio style="width: 100%;height: 50px;" controls="controls">
                                            <source :src="scope.row.audio" :type="scope.row.audioType">
                                        </audio>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="发布时间" min-width="140" align="center"></el-table-column>
                            <el-table-column prop="likeCount" label="获赞数" min-width="100" align="center"></el-table-column>
                            <el-table-column prop="commentCount" label="评论数" min-width="100" align="center"></el-table-column>
                            <el-table-column prop="status" label="状态" min-width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">审核通过</span>
                                    <span v-else-if="scope.row.status=== 2" style="color: #F56C6C">审核拒绝</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center;">
                            <el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
                                :page-size="articleSize" :total="articleTotal" :page-sizes="[10, 20, 50, 100]" :current-page="articlePage" @size-change="handleArticleSizeChange" @current-change="changeArticleClick">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="action-blank"></div>

        <div class="action-bar" v-if="userInfo.id">
            <el-link class="link-btn" :type="btn.type || 'primary'" :icon="btn.icon" :underline="false" v-for="btn in actionBtn" :key="btn.method" @click="action(btn.method)">{{btn.label}}</el-link>
        </div>

        <el-dialog title="绑定角色" :visible.sync="bindShow" :close-on-click-modal="false" width="20%" center zIndex=1999>
			<el-tree :data="roleList" show-checkbox node-key="id" ref="tree" :props="props"
				:default-checked-keys="selectedIds" default-expand-all check-on-click-node> </el-tree>
			<span slot="footer" class="dialog-footer">
				<el-button @click="bindShow = false">取 消</el-button>
				<el-button type="primary" @click="submitBind()">确 定</el-button>
			</span>
		</el-dialog>

        <el-dialog title="充值K币" :visible.sync="rechargeShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form ref="rechargeForm" :model="rechargeForm">
				<el-form-item label="昵称" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.nickName" :disabled="true" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="支付类型" :label-width="formLabelWidth">
					<el-select v-model="rechargeForm.type" placeholder="请选中支付类型">
						<el-option label="苹果" value="0"></el-option>
						<el-option label="微信" value="1"></el-option>
						<el-option label="支付宝" value="2"></el-option>
						<el-option label="银行卡" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="K币数量" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.number" placeholder="请输入数量" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="充值金额" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.price" placeholder="请输入充值金额" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="充值理由" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.remark" placeholder="请输入充值理由" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="rechargeShow = false">取 消</el-button>
				<el-button type="primary" @click="submitRecharge()">确 定</el-button>
			</span>
		</el-dialog>
        
        <el-dialog width="1000px" :visible.sync="recordShow" :close-on-click-modal="false" center zIndex=1999>
            <el-table :max-height="tableHeight" :data="recordList" border style="width: 100%" :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column
                    :prop="col.prop"
                    :label="col.label"
                    :min-width="col.width || 100"
                    :align="col.align || 'center'"
                    v-for="(col, colIndex) in recordColumns"
                    :key="colIndex"
                >
                    <template slot-scope="scope">
                        <renderCell :render="() => col.render(scope.row)" v-if="col.hasOwnProperty('render')"></renderCell>
                        <div v-else>{{ scope.row[col.prop] }}</div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:right;">
                <el-pagination style="margin-top: 10px;" background :hide-on-single-page="false" layout="total, prev, pager, next, sizes"
                    :page-size="recordSize" :total="recordTotal" :page-sizes="[10, 20, 50, 100]" :current-page="recordPage" @size-change="handleRecordSizeChange" @current-change="changeRecordClick">
                </el-pagination>
            </div>
        </el-dialog>

        <!-- <el-dialog title="封禁用户" :visible.sync="disabledVisible" :close-on-click-modal="false" width="25%">
            <el-form ref="disabledForm" :model="disabledForm" :rules="disabledRules">
				<el-form-item label="封禁理由" prop="remark" :label-width="formLabelWidth">
					<el-input type="textarea" :rows="4" v-model="disabledForm.remark" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="客服备注" prop="customerRemark" :label-width="formLabelWidth">
					<el-input type="textarea" :rows="4" v-model="disabledForm.customerRemark" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="disabledVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitDisabled()">确 定</el-button>
			</span>
        </el-dialog> -->

        <delete-dialog ref="deleteDialog" title="封禁用户" :type="0" :showRemark="true" @confirm="deleteConfirm"></delete-dialog>
    </div>
</template>

<script>
import BenzAMRRecorder from 'benz-amr-recorder'
import {
    userInfoFindById,
    userInfoFindByNumber,
    articlePage,
    userRole,
    userBindRole,
    userUpdate,
    userDelSign,
    roleList,
    userRecharge,
    coinRecordPage,
    takeMoneyPage,
    balancePage,
    userDeleteHead,
    albumDelete,
    audioDelete,
} from '@/api/api';
import deleteDialog from '@/components/deleteDialog'
import { TimeSelect } from 'element-ui';
const renderCell = {
    functional: true,
    props: ['render'],
    render: (createElement, context) => context.props.render()
}

export default {
    components: {
        renderCell,
        deleteDialog
    },
    data() {
        return {
            id: null,
            number: null,
            userInfo: {},

            fileDomain: 'https://file.oiki.cc/',
            formLabelWidth: '80px',

            statusAry: [
                { label: '正常', type: 'primary' },
                { label: '注销', type: 'danger' },
                { label: '禁用', type: 'warning' },
                { label: '注销中', type: 'warning' },
            ],

            audio: undefined,
            audioCurrentPosition: 0,

            articlePage: 1,
            articleSize: 10,
            articleTotal: 0,
            articleList: [],

            bindShow: false,

            roleList: [],
            selectedIds: [],
            props: {
				label: 'name'
			},

            rechargeShow: false,
            rechargeForm: {
				userId: '',
				nickName: '',
				type: '',
				number: '',
				price: '',
				remark: ''
			},

            recordFn: undefined,
            recordShow: false,
            recordColumns: [],
            recordPage: 1,
            recordSize: 10,
            recordTotal: 0,
            recordList: [],

            disabledVisible: false,
            disabledForm: {
                remark: '',
                customerRemark: ''
            },
            disabledRules: {
                remark: [
                    { required: true, message: '请输入封号理由', trigger: 'blur' },
                ],
                customerRemark: [
                    // { required: true, message: '请输入客服备注', trigger: 'blur' },
                ],
            }
        }
    },
    computed: {
        tableHeight() {
            return window.innerHeight * .6
        },
        actionBtn() {
            const list = []

            if (this.userInfo?.id) {
                const setTopLabel = this.userInfo.isTop == 0 ? '设为置顶' : '取消置顶'
                const setTopIcon = this.userInfo.isTop == 0 ? 'el-icon-top' : 'el-icon-bottom'
                list.push({ label: setTopLabel, method:'setTop', type: 'primary', icon: setTopIcon })


                const recommendLabel = this.userInfo.isRecommend == 0 ? '设为推荐' : '取消推荐'
                const recommendIcon = this.userInfo.isRecommend == 0 ? 'el-icon-star-off' : 'el-icon-star-on'
                list.push({ label: recommendLabel, method: 'setRecommend', type: 'primary', icon: recommendIcon })
            }

            // list.push({ label: '修改ID', method: 'changeNumber', type: 'warning', icon: 'el-icon-edit' })
            list.push({ label: '绑定角色', method: 'bindRole', type: 'primary', icon: 'el-icon-connection' })

            if ([0,2,3].includes(this.userInfo.status)) {
                const isDisabled = this.userInfo.status == 2
                const disableLabel = !isDisabled ? '禁用该用户' : '解禁该用户'
                const disableIcon = !isDisabled ? 'el-icon-error' : 'el-icon-success'
                const disableType = !isDisabled ? 'warning' : 'success'
                list.push({ label: disableLabel, method: 'disableUser', type: disableType, icon: disableIcon })
            }

            list.push({ label: '充值K币', method: 'recharge', type: 'primary', icon: 'el-icon-coin' })
            list.push({ label: '查看K币记录', method: 'coinRecord', type: 'warning', icon: 'el-icon-notebook-1' })
            list.push({ label: '查看收益记录', method: 'incomeRecord', type: 'primary', icon: 'el-icon-notebook-1' })
            list.push({ label: '查看提现记录', method: 'takeRecord', type: 'warning', icon: 'el-icon-notebook-1' })

            if (this.userInfo?.audioTime > 0) {
                list.push({ label: '删除语音介绍', method: 'deleteVoice', type: 'danger', icon: 'el-icon-delete' })
            }

            return list;
        },
        defaultAvatar() {
            const avatar = {
                1: 'images/other/man.png',
                2: 'images/other/women.png'
            }

            return this.fileDomain + avatar[this.userInfo.gender]
        }
    },
    methods: {
        back() {
            this.$router.back()
        },
        toUserList() {
            this.$router.push('/user/')
        },
        getDetail () {

            let send
            if (this.id) {
                send = userInfoFindById(this.id)
            } else if(this.number) {
                send = userInfoFindByNumber({userNumber: this.number})
            }

            if (!send) return;

            send.then(res => {
                // console.log(res,'res')
                this.userInfo = res

                if (this.userInfo?.headImg) this.userInfo.headImg = this.fileDomain + this.userInfo.headImg
                if (this.userInfo?.realName?.faceImage) this.userInfo.realName.faceImage = this.fileDomain + this.userInfo.realName.faceImage
                if (this.userInfo?.realName?.faceVideo) this.userInfo.realName.faceVideo = this.fileDomain + this.userInfo.realName.faceVideo
                if (this.userInfo?.albumList?.length) {
                    this.userInfo.albums = this.userInfo.albumList.map(item => this.fileDomain + item.image)
                }

                if (this.userInfo?.audio) {
                    const audioAry = this.userInfo.audio.split('.')
                    const audioSuffix = audioAry[audioAry.length - 1]
                    this.userInfo.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                }
                // console.log(this.userInfo)

                this.getArticleList()
            })
        },
        handleArticleSizeChange(size) {
            this.articleSize = size
            this.getArticleList()
        },
         //点击分页事件
        changeArticleClick(page) {
            this.articlePage = page;
            this.getArticleList();
        },
        getArticleList() {
            articlePage({
                current: this.articlePage,
                size: this.articleSize,
                userId: this.userInfo.id
            })
                .then(response => {
                    this.articleTotal = response.total;
                    this.articleList = response.records.map(item => {
                        if (item.image != null && item.image.length > 0) {
                            item.images = item.image.split(",")
                            for(let j = 0; j < item.images.length; j++) {
                                item.images[j] = this.fileDomain + item.images[j]
                            }
                        }
                        if (item.video != null && item.video.length > 0) {
                            item.video = this.fileDomain + item.video
                        }
                        if (item.audio != null && item.audio.length > 0) {
                            item.audio = this.fileDomain + item.audio

                            const audioAry = item.audio.split('.')
                            const audioSuffix = audioAry[audioAry.length - 1]
                            item.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                        }

                        return item
                    });
                })
        },
        handleRecordSizeChange(size) {
            this.recordSize = size
            this.getRecordList();
        },
         //点击分页事件
        changeRecordClick(page) {
            this.recordPage = page;
            this.getRecordList();
        },
        getRecordList() {
            this.recordFn({current: this.recordPage, size: this.recordSize, userNumber: this.userInfo.userNumber}).then(res => {
                this.recordTotal = res.total
                this.recordList = res.records
            })
        },
        //获取选中用户角色列表
		getUserRoleList() {
			if (this.selectedIds && this.selectedIds.length > 0) {
				this.$refs.tree.setCheckedKeys([])
			}
			this.selectedIds = []
			userRole(this.selectUserId, null)
				.then((response) => {
					this.selectedIds = response
				})
		},
        //获取角色列表
		getRoleList() {
			roleList()
				.then((response) => {
					this.roleList = response
				})
		},
        async avatarDeleteHandler() {
            await new Promise((resolve) => {
                this.$confirm('确定要删除此用户头像?', '提示')
                .then(() => resolve(true))
                .catch(() => {})
            })

            userDeleteHead(this.id).then(() => {
				this.getDetail();
			}).catch(() => {})
        },
        // 删除个性签名
        async deleteSign() {
            await new Promise((resolve) => {
                this.$confirm('确定要删除此用户签名?', '提示')
                .then(() => resolve(true))
            })

            userDelSign(this.id).then(res => {
                this.getDetail()
            })
        },
        // 删除相册事件
        async albumDeleteHandler(item) {
            await new Promise((resolve) => {
                this.$confirm('确定要删除此用户相册?', '提示')
                .then(() => resolve(true))
            })
            
            const params = new URLSearchParams()
			params.append("id", [item.id])
            albumDelete({ params }).then(res => {
                this.getDetail()
            }).catch(() => {})
        },
        //提交绑定
		submitBind() {
			console.log(this.$refs.tree.getCheckedKeys())
			this.bindShow = false
			userBindRole({
				userId: this.selectUserId,
				roleIds: this.$refs.tree.getCheckedKeys()
			}).then(() => {
				// window.location.reload() //刷新页面
				this.getDetail() //重新获取数据
			})
		},
        //充值提交事件
		submitRecharge() {
			this.rechargeShow = false
			userRecharge({
				userId: this.rechargeForm.userId,
				type: this.rechargeForm.type,
				number: this.rechargeForm.number,
				price: this.rechargeForm.price,
				remark: this.rechargeForm.remark
			}).then(() => {
				this.getDetail() //重新获取数据
			})
		},
        showRecord() {
            this.recordShow = true
            this.recordPage = 1

            this.getRecordList()
        },
        async playVoice(value) {
            if (!this.audio) {
                this.audio = new BenzAMRRecorder()
                await this.audio.initWithUrl(this.fileDomain + this.userInfo.audio)

                this.audio.setInterval = () => {
                    return setInterval(() => {
                        this.audioCurrentPosition = this.audio.getCurrentPosition()
                    }, 10)
                }

                this.audio.onPlay(() => {
                    this.audio.timeout = this.audio.setInterval()
                })

                this.audio.onResume(() => {
                    this.audio.timeout = this.audio.setInterval()
                })

                this.audio.onPause(() => {
                    clearInterval(this.audio.timeout)
                })

                this.audio.onEnded(() => {
                    clearInterval(this.audio.timeout)
                    this.audioCurrentPosition = parseInt(this.userInfo.audioTime)
                })
            }

            if (this.audio._isPlaying) {
                this.audio.pause()
            } else if (this.audio._isPaused) {
                this.audio.resume()
            } else {
                if (value) {
                    this.audio.setPosition(value)
                } else {
                    this.audio.play()
                }

            }
            // console.log(this.audio,'audio')
        },
        changeAudioPosition(value) {
            if (this.audio && !this.audio._isPlaying) {
                this.audio.setPosition(value)
            } else {
                this.playVoice(value)
            }
        },
        action(methodName) {
            if (typeof this[methodName] === 'function') {
                this[methodName]()
            }
        },
        setTop() {
            userUpdate({
				id: this.userInfo.id,
				isTop: this.userInfo.isTop == 0 ? 1 : 0
			}).then(() => {
				this.getDetail();
			})
        },
        setRecommend() {
            userUpdate({
				id: this.userInfo.id,
				isRecommend: this.userInfo.isRecommend == 0 ? 1 : 0
			}).then(() => {
				this.getDetail();
			})
        },
        changeNumber() {
            
        },
        bindRole() {
            this.bindShow = true
			this.selectUserId = this.userInfo.id
			this.getUserRoleList()
        },
        async disableUser() {
            if (this.userInfo.status !== 2) {
                this.$refs.deleteDialog.showDialog()
            } else {
                this.submitDisabled()
            }
        },
        deleteConfirm(data) {
            this.submitDisabled(data.content, data.remark)
        },
        async submitDisabled(content, remark) {
            const formData = {
                id: this.userInfo.id,
                status: this.userInfo.status !== 2 ? 2 : 0,
            }
            if (this.userInfo.status !== 2) {
                formData.remark = content
                formData.customerRemark = remark
            }
            
            userUpdate(formData).then(() => {
                this.getDetail();
            })
        },
        recharge() {
            this.rechargeShow = true
			this.rechargeForm.nickName = this.userInfo.nickName
			this.rechargeForm.userId = this.userInfo.id
        },
        coinRecord() {
            this.recordFn = coinRecordPage
            this.recordColumns = [
                { label: 'K币数量', prop: 'number' },
                { label: 'K币总数', prop: 'totalNumber' },
                { label: '收支', prop: 'inOut', render: (row) => {
                    const color = row.inOut == 0 ? '#67C23A' : '#F56C6C'
                    const style = `color: ${color}`
                    return (
                        <div style={style}>{ row.inOut == 0 ? '收入' : '支出' }</div>
                    )
                } },
                { label: '打赏类型', prop: 'type', render: (row) => {
                    const typeAry = ['充值','打赏','申请好友','单聊打赏','征友']
                    return (
                        <div>{ typeAry[row.type] }</div>
                    )
                } },
                { label: '用途', prop: 'useDesc' },
                { label: '创建时间', prop: 'createTime' },
            ]
            this.showRecord()
        },
        incomeRecord() {
            this.recordFn = balancePage
            this.recordColumns = [
                { label: '金额', prop: 'money' },
                { label: '剩余金额', prop: 'totalMoney' },
                { label: '收支', prop: 'inOut', render: (row) => {
                    const color = row.inOut == 0 ? '#67C23A' : '#F56C6C'
                    const style = `color: ${color}`
                    return (
                        <div style={style}>{ row.inOut == 0 ? '收入' : '支出' }</div>
                    )
                } },
                { label: '类型', prop: 'type', render: (row) => {
                    const typeAry = ['充值','打赏','申请好友','单聊打赏','征友','动态激励-动态','动态激励-评论','动态激励-回复']
                    return (
                        <div>{ typeAry[row.type] }</div>
                    )
                } },
                { label: '状态', prop: 'status', render: (row) => {
                    const statusAry = ['待审核','通过','拒绝','已到账']
                    const style = row.status == 3 ? 'color: #67C23A' : ''
                    return (
                        <div style={style}>{ statusAry[row.status] }</div>
                    )
                } },
                { label: '用途', prop: 'useDesc' },
                { label: '创建时间', prop: 'createTime' },
            ]
            this.showRecord()
        },
        takeRecord() {
            this.recordFn = takeMoneyPage
            this.recordColumns = [
                { label: '申请提现金额', prop: 'money', render: (row) => {
                    const style = 'text-decoration: line-through;'
                    return (
                        <div style={style}>{ row.money }</div>
                    )
                } },
                { label: '手续费', prop: 'handMoney' },
                { label: '实际提现金额', prop: 'realMoney', render: (row) => {
                    const style = 'color: #F56C6C'
                    return (
                        <div style={style}>{ row.realMoney }元</div>
                    )
                } },
                { label: '开户名', prop: 'realName' },
                { label: '开户行', prop: 'bankName' },
                { label: '银行卡号', prop: 'bankCard' },
                { label: '状态', prop: 'status', render: (row) => {
                    const statusAry = ['待审核','审核通过','审核拒绝']
                    const colorAry = ['#E6A23C','#67C23A','#F56C6C']
                    const style = `color: ${colorAry[row.status]}`
                    return (
                        <div style={style}>{ statusAry[row.status] }</div>
                    )
                } },
                { label: '创建时间', prop: 'createTime' },
                { label: '备注', prop: 'remark' },
            ]
            this.showRecord()
        },
        async deleteVoice() {
            await new Promise((resolve) => {
                this.$confirm('确定要删除此用户的语音?', '提示')
                .then(() => resolve(true))
                .catch(() => {})
            })
            
            audioDelete(this.id).then(() => {
                this.getDetail();
            })
        },
    },
    created() {
        this.id = this.$route.params.id
        this.number = this.$route.params.number
        this.getRoleList()

        if (this.id || this.number) {
            this.getDetail()
        }
        console.log(this.$route,'$route')
    },
    destroyed() {
        if (this.audio) {
            this.audio.stop()
        }
        console.log('destroyed')
    }
}
</script>

<style scoped>
    .w-full {width: 100%;}
    .text-left {text-align: left;}


    .flex-items-start {
        align-items: flex-start;
    }

    .page {
        min-height: calc(100vh - 60px - 40px);
    }

    .info-title {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .imgs .img {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .voice {
        line-height: 20px;
        background-color: #b6e2e6;
        padding: 5px 20px;
        border-radius: 10px;
        user-select: none;
        cursor: pointer;
    }

    .userinfo-box {
        margin-top: 20px;
        border-bottom: 1px solid #ddd;
        display: flex;
    }
    
    .userinfo-box .box {
        width: 25%;
        padding-bottom: 20px;
        box-sizing: border-box;
    }

    .userinfo-box .box:not(:first-child) {
        padding-left: 20px;
        border-left: 1px solid #ddd;
    }
    
    .userinfo-box .item {
        display: flex;
        align-items: center;
    }

    .userinfo-box .border-bottom {
        padding-bottom: 5px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 5px;
    }

    .userinfo-box .item .label {
        font-size: 14px;
        color: #999;
        text-wrap: nowrap;
    }
    .userinfo-box .item .value {
        font-size: 14px;
        color: #666;
    }
    
    .audio-box {
        display: flex;
        align-items: center;
        .audio {
            width: 100px;
            margin: 0 10px;
        }

        .audio-time {
            margin: 0 10px;
        }
    }


    .detail-box {
        margin-top: 30px;
    }
    .detail-box .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .detail-box .row .col {
        display: flex;
        margin-right: 40px;
    }
    
    .detail-box .row .col:not(.flex-items-start) {
        align-items: center;
    }

    .detail-box .row .col .detail-title {
        flex-shrink: 0;
    }
    
    .action-blank {
        height: 20px;
    }

    .action-bar {
        background-color: #fff;
        padding: 10px;
        position: sticky;
        z-index: 8;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateY(20px);
        box-shadow: 0 0 5px rgba(0,0,0,.1);
    }

    .action-bar .link-btn {
        margin: 0 20px;
        user-select: none;
    }

    .flex-content {
        display: flex;
        align-items: center;
    }

    .flex-content .el-button {
        margin-left: 10px;
    }

    .view-more {
        width: fit-content;
        color: #409EFF;
        margin: auto;
        cursor: pointer;
        user-select: none;
    }

    .pre {
        white-space: pre-line;
    }
</style>