<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="动态内容">
                    <el-input v-model="query.content" placeholder="请输入动态内容" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态" style="width: 120px;">
                        <el-option label="待审核" value="0"></el-option>
						<el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-select v-model="query.isTop" placeholder="请选择置顶" style="width: 120px;">
                        <el-option label="全部" value=""></el-option>
						<el-option label="已置顶" value="1"></el-option>
                        <el-option label="未置顶" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推荐">
                    <el-select v-model="query.isRecommend" placeholder="请选择推荐" style="width: 120px;">
                        <el-option label="全部" value=""></el-option>
						<el-option label="已推荐" value="1"></el-option>
                        <el-option label="未推荐" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否实名">
					<el-select v-model="query.isRealName" placeholder="是否实名" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="min-width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="id" label="动态ID" width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/article/' + scope.row.id" target="_blank">{{ scope.row.id }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="发布人" width="120" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="发布人ID" width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="gender" label="性别" width="50" align="center">
                <template slot-scope="scope">
                    {{ {1: '男', 2: '女'}[scope.row.gender] }}
                </template>
            </el-table-column>
            <el-table-column prop="content" label="动态内容" min-width="150" align="center"></el-table-column>
            <el-table-column prop="images" label="动态图片" min-width="150" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                        :preview-src-list="scope.row.images" >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="video" label="动态音频" width="200" align="center">
                <template slot-scope="scope">
                    <video v-if="scope.row.video" muted="muted" class="video" :src="scope.row.video" type="video/mp4" :poster="scope.row.images[0]" autoplay="autoplay" style="width: 150px; height: 150px; padding-top: 10px;" controls="controls" loop="-1">
                        <p>你的浏览器不支持video标签.</p>
                    </video>
                    <audio style="width: 100%;height: 50px;" v-if="scope.row.audio" controls="controls">
                        <source :src="fileDomain + scope.row.audio" :type="scope.row.audioType">
                    </audio>
                </template>
            </el-table-column>
            <el-table-column prop="isRealName" label="是否实名" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.isRealName == 1 ? '是' : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="80" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">审核通过</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">审核拒绝</span>
				</template>
			</el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="150" align="center"></el-table-column>
            <el-table-column prop="recommendTime" label="推荐时间" width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="240" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.isRecommend === 0 && scope.row.status === 1" size="mini" icon="el-icon-star-off" @click="recommendClick(scope.row, 1)">设为推荐
					</el-button>
					<el-button v-else-if="scope.row.isRecommend === 1 && scope.row.status === 1" type="info" size="mini" icon="el-icon-circle-close" @click="recommendClick(scope.row, 0)">取消推荐
					</el-button>

                    <el-button type="primary" size="mini" v-if="scope.row.isTop === 0 && scope.row.status === 1" @click="setTop(scope.row, 1)">置顶</el-button>
                    <el-button type="warning" size="mini" v-else-if="scope.row.isTop === 1 && scope.row.status === 1" @click="setTop(scope.row, 0)">已置顶</el-button>

                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <delete-dialog ref="deleteDialog" title="删除动态" :type="1" @confirm="deleteConfirm"></delete-dialog>
    </div>
</template>

<script>
import {
    articlePage,
    articleUpdate,
    articleCheck,
    articleDelete,
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
import deleteDialog from '@/components/deleteDialog'
export default {
    components: {
        deleteDialog
    },
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                content: "",
                status: "",
                isTop: "",
                isRealName: "",
                isRecommend: "",
            },
            formLabelWidth: "80px",

            fileDomain: 'https://file.oiki.cc/'
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getArticleList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getArticleList();
        },
        //获取待审核动态分页列表
        getArticleList() {
            articlePage({
                current: this.page,
                size: this.size,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                content: this.query.content,
                isTop: this.query.isTop,
                isRealName: this.query.isRealName,
                isRecommend: this.query.isRecommend,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let article = this.dataList[i];
                        if (article.image != null && article.image.length > 0) {
                            article.images = article.image.split(",")
                            for(let j = 0; j < article.images.length; j++) {
                                article.images[j] = this.fileDomain + article.images[j]
                            }
                        }
                        if (article.video != null && article.video.length > 0) {
                            article.video = this.fileDomain + article.video
                        }

                        if (article.audio) {
                            const audioAry = article.audio.split('.')
                            const audioSuffix = audioAry[audioAry.length - 1]
                            article.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                        }
                    }
                })
        },
        agreeClick(id) {
            articleCheck({
                "id": id,
                "status": 1
            }).then(response => {
                this.getArticleList();
            })
        },
        refuseClick(id) {
            articleCheck({
                "id": id,
                "status": 2
            }).then(response => {
                this.getArticleList();
            })
        },
        //设置推荐
		recommendClick(row, isRecommend) {
			articleUpdate({
				id: row.id,
				isRecommend: isRecommend
			}).then(() => {
				this.getArticleList();
			})
		},
        async setTop(row, isTop) {
            const formData = { id: row.id, isTop }
            if (isTop === 1) {
                await new Promise((resolve) => {
                    this.$prompt('请填写该动态的置顶标签', '置顶', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputPlaceholder: '请填写该动态的置顶标签最多4个字 不填时则不展示标签',
                        type: 'warning',
                        closeOnClickModal: false
                    }).then(({ value }) => {
                        if (value && value.length > 4) {
                            this.$message.error('置顶标签最多4个字')
                            return;
                        }
    
                        formData.topLabel = value
                        resolve()
                    })
                })
            }

            articleUpdate(formData).then(() => {
                this.getArticleList()
            })
        },
        //删除事件
        deleteClick(id) {
            /* this.$prompt('请输入删除原因?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
                closeOnClickModal: false
			}).then(({ value }) => {
                if (!value || value.trim() == '') {
                    this.$message.error('请输入删除理由')
                    return;
                }
				this.deleteArticle(id, value)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			}); */

            // this.deleteFormVisible = true

            
            this.$refs.deleteDialog.setId(id)
            this.$refs.deleteDialog.showDialog()
        },
        deleteConfirm(data) {
            this.deleteArticle(data.id, data.content)
        },
        deleteArticle(id, remark) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			params.append("remark", remark)
            this.deleteLoading = true
			articleDelete({ params })
				.then(() => {
					this.getArticleList();

                    this.deleteLoading = false
                    this.deleteFormVisible = false
                    this.$nextTick(() => {
                        this.deleteForm.reason = ''
                        this.$refs.deleteForm.resetFields()
                    })
				})
        },
        
    },
    mounted() {
        this.getArticleList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>