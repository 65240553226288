<template>
	<div>
		<div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="手机号">
					<el-input v-model="query.phone" placeholder="手机号" style="width: 110px;" @keydown.enter.native="confirmSearch"></el-input>
				</el-form-item>
				<el-form-item label="昵称">
					<el-input v-model="query.nickName" placeholder="昵称" style="width: 155px;" @keydown.enter.native="confirmSearch"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 110px" @keydown.enter.native="confirmSearch"></el-input>
				</el-form-item>
				<el-form-item label="是否实名">
					<el-select v-model="query.isRealName" placeholder="是否实名" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="未实名" value=0></el-option>
						<el-option label="已实名" value=1></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否贵族">
					<el-select v-model="query.isVip" placeholder="是否贵族" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="是" value=1></el-option>
						<el-option label="否" value=0></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="性别">
					<el-select v-model="query.gender" placeholder="性别" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="男" value=1></el-option>
						<el-option label="女" value=2></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色">
					<el-select v-model="query.roleCode" placeholder="角色" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in roleList" :key="item.value" :label="item.name" :value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="推荐用户">
                    <template #label>
                        推荐用户
                        <el-popover
                            placement="bottom"
                            >
                            <div>
                                排序规则：按后台将该用户设置为推荐的时间进行排序，最新设置的排在最前面；<br/>
                                有效期规则：已被设置为推荐的用户，连续{{autoCancelRecommend || 'N'}}天没有登录记录，则自动取消推荐；相册内容有变化时，自动取消推荐；{{autoCancelRecommend || 'N'}}天由后台配置
                            </div>
                            <i class="el-icon-warning-outline" slot="reference"></i>
                        </el-popover>
                    </template>
					<el-select v-model="query.isRecommend" placeholder="推荐用户" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="推荐" :value="1"></el-option>
						<el-option label="未推荐" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="置顶用户">
					<el-select v-model="query.isTop" placeholder="置顶用户" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="置顶" :value="1"></el-option>
						<el-option label="未置顶" :value="0"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="社交人格">
					<el-select v-model="query.personality" placeholder="社交人格" style="width: 90px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="支配者" value=1></el-option>
						<el-option label="臣服者" value=2></el-option>
						<el-option label="双向者" value=3></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="query.status" placeholder="状态" style="width: 90px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="正常" :value="0"></el-option>
						<el-option label="注销" :value="1"></el-option>
						<el-option label="禁用" :value="2"></el-option>
						<el-option label="注销中" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="上级用户ID">
					<el-input v-model="query.upUserNumber" placeholder="上级用户ID" style="width: 110px" @keydown.enter.native="confirmSearch"></el-input>
				</el-form-item>
				<el-form-item label="相册照片数">
					<el-input v-model="query.minAlbumCount" style="width: 60px;"></el-input>
					 - 
					<el-input v-model="query.maxAlbumCount" style="width: 60px;"></el-input>
				</el-form-item>
				<el-form-item label="注册时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="最后登录时间">
					<el-date-picker v-model="lastDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="注册来源">
					<el-select v-model="query.platform" placeholder="注册来源" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="ios" value="ios"></el-option>
						<el-option label="android" value="android"></el-option>
						<el-option label="h5" value="h5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="注册渠道">
					<el-select v-model="query.channel" placeholder="注册来源" style="width: 100px;">
						<el-option :label="item" :value="item == '全部' ? '' : item" v-for="item in channelList" :key="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
					<el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
					<el-button type="success" @click="addClick">添加</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-dialog title="添加用户" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="addForm">
				<el-form-item label="用户账号" :label-width="formLabelWidth">
					<el-input v-model="addForm.username" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="用户密码" :label-width="formLabelWidth">
					<el-input v-model="addForm.password" placeholder="默认密码：123456" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" :label-width="formLabelWidth">
					<el-input v-model="addForm.phone" placeholder="请输入手机号码" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="用户昵称" :label-width="formLabelWidth">
					<el-input v-model="addForm.nickName" placeholder="请输入用户昵称" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="addUser">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="编辑用户" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form ref="updateForm" :model="updateForm" :rules="rules">
				<el-form-item label="手机号" :label-width="formLabelWidth">
					<el-input v-model="updateForm.phone" :disabled="true" placeholder="请输入手机号码" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="密码" :label-width="formLabelWidth" prop="password">
					<el-input v-model="updateForm.password" placeholder="请输入新密码" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="昵称" :label-width="formLabelWidth">
					<el-input v-model="updateForm.nickName" placeholder="请输入昵称" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="状态" :label-width="formLabelWidth">
					<el-select v-model="updateForm.status" placeholder="用户状态">
						<el-option label="正常" value="0"></el-option>
						<el-option label="注销" value="1"></el-option>
						<el-option label="禁用" value="2"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updateShow = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate('updateForm')">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="绑定角色" :visible.sync="bindShow" :close-on-click-modal="false" width="20%" center zIndex=1999>
			<el-tree :data="roleList" show-checkbox node-key="id" ref="tree" :props="props"
				:default-checked-keys="selectedIds" default-expand-all check-on-click-node> </el-tree>
			<span slot="footer" class="dialog-footer">
				<el-button @click="bindShow = false">取 消</el-button>
				<el-button type="primary" @click="submitBind()">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="充值K币" :visible.sync="rechargeShow" width="25%" center zIndex=1999>
			<el-form ref="rechargeForm" :model="updateForm">
				<el-form-item label="昵称" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.nickName" :disabled="true" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="支付类型" :label-width="formLabelWidth">
					<el-select v-model="rechargeForm.type" placeholder="请选中支付类型">
						<el-option label="苹果" value="0"></el-option>
						<el-option label="微信" value="1"></el-option>
						<el-option label="支付宝" value="2"></el-option>
						<el-option label="银行卡" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="K币数量" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.number" placeholder="请输入数量" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="充值金额" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.price" placeholder="请输入充值金额" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="充值理由" :label-width="formLabelWidth">
					<el-input v-model="rechargeForm.remark" placeholder="请输入充值理由" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="rechargeShow = false">取 消</el-button>
				<el-button type="primary" @click="submitRecharge()">确 定</el-button>
			</span>
		</el-dialog>

		<el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="phone" label="手机号" min-width="100" align="center"></el-table-column>
			<el-table-column prop="nickName" label="昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="编号" min-width="100" align="center"></el-table-column>
			<el-table-column prop="headImg" label="头像" width="80" align="center">
				<template slot-scope="scope">
					<div class="img-content">
						<el-image v-if="scope.row.headImg" style="width: 40px; height: 40px;"
							:src="'https://file.oiki.cc/' + scope.row.headImg" :preview-src-list="['https://file.oiki.cc/' + scope.row.headImg]">
						</el-image>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="isRealName" label="是否实名" min-width="80" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.isRealName === 0" style="color: #bec713">未实名</span>
					<span v-else-if="scope.row.isRealName === 1" style="color: #119db9">已实名</span>
				</template>
			</el-table-column>
			<el-table-column prop="isVip" label="是否贵族" min-width="80" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.isVip === 0" style="color: #909399">普通用户</span>
					<span v-else-if="scope.row.isVip === 1" style="color: #409EFF">贵族用户</span>
				</template>
			</el-table-column>
			<el-table-column prop="gender" label="性别" min-width="50" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.gender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.gender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.gender === 2" style="color: #b91187">女</span>
				</template>
			</el-table-column>
			<el-table-column prop="age" label="年龄" min-width="50" align="center"></el-table-column>
			<!-- <el-table-column prop="pwNumber" label="牵线次数" min-width="100" align="center"></el-table-column> -->
			<el-table-column prop="personalityName" label="社交人格" min-width="80" align="center"></el-table-column>
			<el-table-column prop="totalCoin" label="K币数量" min-width="100" align="center"></el-table-column>
			<el-table-column prop="albumCount" label="相册照片数" width="100" align="center"></el-table-column>
			<el-table-column prop="articleCount" label="动态数" width="70" align="center"></el-table-column>
			<el-table-column prop="platform" label="来源" min-width="70" align="center"></el-table-column>
			<el-table-column prop="channel" label="渠道" min-width="80" align="center"></el-table-column>
			<el-table-column prop="appVersion" label="版本" min-width="80" align="center"></el-table-column>
			<el-table-column prop="upUserNumber" label="上级用户" min-width="100" align="center">
				<template slot-scope="scope">
					<a :href="'/userNumber/' + scope.row.upUserNumber" target="_blank">{{ scope.row.upUserNumber }}</a>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="images" label="相册" width="280" align="center">
				<template slot-scope="scope">
					<el-image v-if="scope.row.images" style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                        :preview-src-list="scope.row.images" >
                    </el-image>
				</template>
			</el-table-column> -->
			<el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
			<el-table-column prop="lastTime" label="最后登录时间" min-width="140" align="center"></el-table-column>
			<el-table-column prop="status" label="状态" min-width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status === 0" style="color: #67C23A">正常</span>
					<span v-else-if="scope.row.status === 1" style="color: #909399">注销</span>
					<span v-else-if="scope.row.status === 2" style="color: #E6A23C">禁用</span>
					<span v-else-if="scope.row.status === 3" style="color: #909399">注销中</span>
					<span v-else-if="scope.row.isDelete === 1" style="color: #F56C6C">删除</span>
				</template>
			</el-table-column>
			<el-table-column prop="roleName" label="角色" min-width="70" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<!-- <el-button v-if="scope.row.isRecommend === 0" size="mini" icon="el-icon-star-off" @click="recommendClick(scope.row, 1)">设为推荐
					</el-button>
					<el-button v-else-if="scope.row.isRecommend === 1" type="info" size="mini" icon="el-icon-circle-close" @click="recommendClick(scope.row, 0)">取消推荐
					</el-button>
					<el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
					</el-button>
					<el-button type="success" size="mini" @click="rechargeClick(scope.row)">充值K币
					</el-button>
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除
					</el-button>
					<el-button type="primary" size="mini" icon="el-icon-connection" @click="bindRole(scope.row.id)">绑定角色
					</el-button> -->
					<el-link type="primary" @click="toDetail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {
	userPage,
	userSave,
	userDelete,
	userDeleteHead,
	userUpdate,
	roleList,
	userBindRole,
	userRole,
	userRecharge,
	getUserChannel,
    dict
} from '@/api/api';
import setTableHeight from '@/utils/setTableHeight'
export default {
	data() {
		return {
			fileDomain: 'https://file.oiki.cc/',

			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
			addShow: false,
			updateShow: false,
			bindShow: false,
			selectUserId: '', //绑定角色选中的userId
			roleIds: [], //选中的角色id
			roleList: [], //角色列表
			selectedIds: [], //默认选中的id
			props: {
				label: 'name'
			},
			addForm: {
				username: '',
				password: '',
				nickName: '',
				phone: ''
			},
			channelList: [],
			query: {
				phone: '',
				nickName: '',
				userNumber: '',
				isRealName: '',
				isVip: '',
				gender: '',
				personality: '',
				roleCode: '',
				upUserNumber: '',
				isRecommend: '',
				isTop: '',
				minAlbumCount: '',
				maxAlbumCount: '',
			},
			updateForm: {
				id: '',
				password: '',
				phone: '',
				status: '',
				nickName: ''
			},
			formLabelWidth: '80px',

			rechargeForm: {
				userId: '',
				nickName: '',
				type: '',
				number: '',
				price: '',
				remark: ''
			},

			rechargeShow: false,

			// 表单验证，需要在 el-form-item 元素中增加 prop 属性
			rules: {
				password: [{
					required: true,
					message: '密码不可为空',
					trigger: 'blur'
				}]
			},

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			selectDate: [],
			lastDate: [],
            autoCancelRecommend: undefined
		}
	},
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		//添加用户事件
		addClick() {
			this.addShow = true
		},
		confirmSearch() {
			this.search()
		},
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getUserList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.selectDate = []
			this.lastDate = []

			this.page = 1
			this.getUserList();
		},
		// 导出列表数据
		exportData() {
			this.$prompt('请输入导出数据条数(默认100)','导出数据', {
				closeOnClickModal: false
			}).then(({value}) => {
				let number = value != null ? parseInt(value) : 100
				if (isNaN(number)) {
					this.$message.error('请输入整型数值！')
					return;
				} else if (number > 2147483647) {
					this.$message.info('数值超过int最大长度，已变更为2147483647！')
					number = 2147483647
				}

				const exportUrl = '/api/user/export'

				const params = {
					"current": 1,
					"size": number,
					"phone": this.query.phone,
					"nickName": this.query.nickName,
					"userNumber": this.query.userNumber,
					"isRealName": this.query.isRealName,
					"isVip": this.query.isVip,
					"gender": this.query.gender,
					"personality": this.query.personality,
					"roleCode": this.query.roleCode,
					"isRecommend": this.query.isRecommend,
					"isTop": this.query.isTop,
					"platform": this.query.platform,
					"channel": this.query.channel,
					"upUserNumber": this.query.upUserNumber,
					"status": this.query.status,
					"minAlbumCount": this.query.minAlbumCount,
					"maxAlbumCount": this.query.maxAlbumCount,
					"startDate": this.selectDate != null ? this.selectDate[0] : null,
					"endDate": this.selectDate != null ? this.selectDate[1] : null,
					"lastStartDate": this.lastDate != null ? this.lastDate[0] : null,
					"lastEndDate": this.lastDate != null ? this.lastDate[1] : null
				}

				let urlParams = '?'
				Object.keys(params).forEach(key => {
					const value = params[key]
					if (value) {
						urlParams += '&' + key + '=' + encodeURIComponent(value)
					}
				})

				console.log(urlParams,'urlParams')
				const fileName = '用户列表.xlsx'
				const xhr = new XMLHttpRequest()
				xhr.open('GET', exportUrl + urlParams, true)
				xhr.responseType = 'blob'
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token)
				xhr.onload = () => {
					if (xhr.status == 200) {
						const type = xhr.getResponseHeader('Content-Type')
						const blob = new Blob([xhr.response], {type})
						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(blob, fileName)
						} else {
							const URL = window.URL || window.webkitURL
							const blobUrl = URL.createObjectURL(blob)

							const a = document.createElement('a')
							if (typeof a.download === 'undefined') {
								window.open(blobUrl)
							} else {
								a.href = blobUrl
								a.download = fileName
								document.body.appendChild(a)
								a.click()
								a.remove()
							}
						}
					} else {
						this.$message.error('导出失败')
					}
				}
				xhr.send()
			})
			
		},
		getDefaultAvatar(gender) {
			const avatar = {
                1: 'images/other/man.png',
                2: 'images/other/women.png'
            }

			return /* this.fileDomain +  */avatar[gender]
		},
		handleSizeChange(size) {
			this.size = size
			this.getUserList()
		},
		changeClick(page) {
			this.page = page
			this.getUserList()
		},
		//获取用户列表接口
		getUserList() {
			userPage({
				"current": this.page,
				"size": this.size,
				"phone": this.query.phone,
				"nickName": this.query.nickName,
				"userNumber": this.query.userNumber,
				"isRealName": this.query.isRealName,
				"isVip": this.query.isVip,
				"gender": this.query.gender,
				"personality": this.query.personality,
				"roleCode": this.query.roleCode,
				"isRecommend": this.query.isRecommend,
				"isTop": this.query.isTop,
				"platform": this.query.platform,
				"channel": this.query.channel,
				"upUserNumber": this.query.upUserNumber,
				"status": this.query.status,
				"minAlbumCount": this.query.minAlbumCount,
				"maxAlbumCount": this.query.maxAlbumCount,
				"startDate": this.selectDate != null ? this.selectDate[0] : null,
				"endDate": this.selectDate != null ? this.selectDate[1] : null,
				"lastStartDate": this.lastDate != null ? this.lastDate[0] : null,
				"lastEndDate": this.lastDate != null ? this.lastDate[1] : null
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
					for (let index = 0; index < response.records.length; index++) {
						const user = response.records[index];
						if (!user.personalityName) {
							user.personalityName = "未明确"
						}
						if (user.images) {
							const images = user.images.split(',')
							const length = images.length
							user.imagesLength = length
							if (length > 5) {
								//相册图片数量超过5张的话，截取5张最新的用来展示
								user.images = images.splice(5)
							}
						}
					}
				})
				.catch((error) => {
					console.log(error)
				});
		},
		// 获取用户注册渠道列表
		getUserChannelList() {
			getUserChannel().then(res => {
				if (res?.length > 0) {
					this.channelList = ['全部', ...res]
				}
			}).catch(() => {})
		},
		//新增用户
		addUser() {
			if (this.addForm.password.length == 0 || this.addForm.password == '' || this.addForm.password == null) {
				this.addForm.password = '123456';
			}
			userSave({
				"username": this.addForm.username,
				"password": this.addForm.password,
				"nickName": this.addForm.nickName,
				"phone": this.addForm.phone
			})
				.then(() => {
					this.getUserList() //保存成功，重新获取数据
				})
			this.addShow = false
		},
		//设置推荐
		recommendClick(row, isRecommend) {
			userUpdate({
				id: row.id,
				isRecommend: isRecommend
			}).then(() => {
				this.getUserList();
			})
		},
		//编辑用户事件
		updateClick(row) {
			this.updateShow = true
			this.updateForm.id = row.id
			this.updateForm.phone = row.phone
			this.updateForm.status = row.status.toString()
			this.updateForm.nickName = row.nickName
		},
		//提交用户编辑事件
		submitUpdate(formName) {
			// 为表单绑定验证功能
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.updateShow = false
					userUpdate({
						"id": this.updateForm.id,
						"password": this.updateForm.password,
						"status": this.updateForm.status,
						"nickName": this.updateForm.nickName
					}).then(() => {
						this.getUserList();
					})
				}
			})
		},
		//删除头像
		deleteClick(id) {
			this.$confirm('确定要删除此用户头像?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteUserHeadImage(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
		},
		//删除头像请求
		deleteUserHeadImage(id) {
			userDeleteHead(id).then(() => {
				this.getUserList();
			})
		},
		//删除用户事件
		deleteById(id) {
			this.$confirm('确定要删除此用户?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteUser(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
		},
		//删除用户接口
		deleteUser(id) {
			let params = new URLSearchParams()
			params.append("idList", [id])
			userDelete({ params })
				.then(() => {
					// window.location.reload() //刷新页面
					this.getUserList() //删除成功，重新获取数据
				})
		},
		//获取角色列表
		getRoleList() {
			roleList()
				.then((response) => {
					console.log(response)
					this.roleList = response
				})
		},
		//获取选中用户角色列表
		getUserRoleList() {
			if (this.selectedIds && this.selectedIds.length > 0) {
				this.$refs.tree.setCheckedKeys([])
			}
			this.selectedIds = []
			userRole(this.selectUserId, null)
				.then((response) => {
					this.selectedIds = response
				})
		},
		//绑定角色
		bindRole(id) {
			this.bindShow = true
			this.selectUserId = id
			this.getUserRoleList()
		},
		//提交绑定
		submitBind() {
			console.log(this.$refs.tree.getCheckedKeys())
			this.bindShow = false
			userBindRole({
				userId: this.selectUserId,
				roleIds: this.$refs.tree.getCheckedKeys()
			}).then(() => {
				window.location.reload() //刷新页面
				this.getUserList() //重新获取数据
			})
		},
		//点击充值按钮
		rechargeClick(row) {
			this.rechargeShow = true
			this.rechargeForm.nickName = row.nickName
			this.rechargeForm.userId = row.id
		},
		//充值提交事件
		submitRecharge() {
			this.rechargeShow = false
			userRecharge({
				userId: this.rechargeForm.userId,
				type: this.rechargeForm.type,
				number: this.rechargeForm.number,
				price: this.rechargeForm.price,
				remark: this.rechargeForm.remark
			}).then(() => {
				this.getUserList() //重新获取数据
			})
		},
		toDetail(id) {
			//this.$router.push(`/user/${id}`)
			//改变需求，打开新窗口
			let route = this.$router.resolve({path: `/user/${id}`})
			window.open(route.href, '_blank')
		},
        // 从字典中获取自动取消推荐日期
        getAutoCancelRecommend() {
            dict({code: 'USER_NO_LOGIN_DAY'}).then(res => {
                const { records } = res
                const [item] = records
                if (item && item.value) {
                    this.autoCancelRecommend = item.value
                }
                console.log(this.autoCancelRecommend,'getAutoCancelRecommend res')
            })
        }
	},
	created() {
		//获取用户列表
		this.getUserList()
		//获取角色列表
		this.getRoleList()
		// 获取用户注册渠道
		this.getUserChannelList()
        this.getAutoCancelRecommend()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>
<style scoped>
	.flex-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.img-content {
		padding: 10px 0 0;
	}

	.delete-text {
		color: #F56C6C;
		margin-left: 10px;
	}
</style>