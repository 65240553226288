<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="发起者昵称">
                    <el-input v-model="query.nickName" placeholder="发起者昵称" style="width: 130px;" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="发起者ID">
                    <el-input v-model="query.userNumber" placeholder="发起者ID" style="width: 100px;" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="匹配状态">
                    <el-select v-model="query.status" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in statusAry" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="交友状态">
                    <el-select v-model="query.friendStatus" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in friendStatusAry" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否使用匹配卡">
                    <el-select v-model="query.isMatchCard" style="width: 90px">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否使用同城卡">
                    <el-select v-model="query.isCityCard" style="width: 90px">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="是否需要送礼">
                    <el-select v-model="query.isGift" style="width: 90px">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="发起时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
					<!-- <el-button type="warning" icon="el-icon-circle-plus-outline" @click="addRow">添加</el-button> -->
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="发起者昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="发起者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank" >{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="createTime" label="发起时间" min-width="100" align="center"></el-table-column>
			<el-table-column prop="status" label="匹配状态" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="statusColor[scope.row.status]">{{ statusAry[scope.row.status] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="giftPrice" label="礼物价值" min-width="100" align="center"></el-table-column>
            <el-table-column prop="toNickName" label="被发起者昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="toUserNumber" label="被发起者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.toUserId" target="_blank" >{{ scope.row.toUserNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="isMatchCard" label="是否使用匹配卡" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="['color-warning','color-success'][scope.row.isMatchCard]">{{ ['否','是'][scope.row.isMatchCard] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="isCityCard" label="是否使用同城卡" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="['color-warning','color-success'][scope.row.isCityCard]">{{ ['否','是'][scope.row.isCityCard] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="friendStatus" label="交友状态" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="friendStatusColor[scope.row.friendStatus]">{{ friendStatusAry[scope.row.friendStatus] }}</span>
                </template>
            </el-table-column>
			<!-- <el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button type="warning" size="mini" @click="editRow(scope.row)">修改</el-button>
					<el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <!-- <el-dialog width="600px" title="" :visible.sync="formVisible" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" :rules="formRules" style="text-align: left;">
                <el-form-item prop="content" label="记录内容">
                    <el-input v-model="form.content" placeholder="请输入记录内容"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="匹配角色">
                    <el-select v-model="form.type" placeholder="匹配角色" style="width: 140px;">
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="formLoading" @click="formConfirm">确 定</el-button>
			</div>
        </el-dialog> -->
    </div>
</template>

<script>
import {
    matchFriendPage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
                userNumber: '',
                status: '',
                friendStatus: '',
                isMatchCard: '',
                isCityCard: '',
                isGift: '',
            },

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			selectDate: [],
            
            statusAry: ['成功','失败'],
            statusColor: ['color-success', 'color-danger'],
            friendStatusAry: ['未沟通','沟通中','交友成功','交友失败'],
            friendStatusColor: ['color-warning', 'color-primary', 'color-success', 'color-danger'],

            formVisible: false,
            formLoading: false,
            form: {},
            formRules: {
                content: [
                    { required: true, message: '请输入记录内容' }
                ],
                type: [
                    { required: true, message: '请选择匹配角色' }
                ]
            }
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getRecordList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.selectDate = []

			this.page = 1
			this.getRecordList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getRecordList()
		},
		changeClick(page) {
			this.page = page
			this.getRecordList()
		},
		//获取记录列表接口
		getRecordList() {
			matchFriendPage({
				"current": this.page,
				"size": this.size,
				'nickName': this.query.nickName,
                'userNumber': this.query.userNumber,
                'status': this.query.status,
                'friendStatus': this.query.friendStatus,
                'isMatchCard': this.query.isMatchCard,
                'isCityCard': this.query.isCityCard,
                'isGift': this.query.isGift,
                "startTime": this.selectDate != null ? this.selectDate[0] : '',
                "endTime": this.selectDate != null ? this.selectDate[1] : '',
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
				.catch((error) => {
					console.log(error)
				});
		},
        /* formConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const formData = {...this.form}

                    matchProductRecordUpdate(formData).then(res => {
                        this.formVisible = false
                        this.getRecordList()
                    })
                }
            })
        },
        addRow() {
            this.form = {
                content: '',
                type: '',
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        editRow(row) {
            this.form = {
                id: row.id,
                content: row.content,
                type: row.type,
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        deleteRow(id) {
            this.$confirm('确定要删除此内容?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
                params.append("idList", [id])
                matchProductRecordDelete({ params })
                    .then(() => {
                        this.getRecordList()
                    })
			})
        } */
	},
	created() {
		//获取记录列表
		this.getRecordList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
</style>