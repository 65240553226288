<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="分享者ID">
                    <el-input v-model="query.userNumber" placeholder="分享者ID" style="width: 120px" @keydown.enter.native="search"></el-input>
                </el-form-item>
				<!-- <el-form-item label="分享者昵称">
					<el-input v-model="query.nickName" placeholder="分享者昵称" style="width: 160px;" @keydown.enter.native="search"></el-input>
				</el-form-item> -->
				<el-form-item label="分享页面类型">
					<el-select v-model="query.type" placeholder="分享页面类型" style="width: 120px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="页面ID">
                    <el-input v-model="query.businessId" placeholder="页面ID" style="width: 120px" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="分享至">
					<el-select v-model="query.shareTo" placeholder="分享至" style="width: 120px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in shareToAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分享时间">
					<el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
                    <el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="userNumber" label="分享者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="nickName" label="分享者昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="type" label="分享页面类型" min-width="80" align="center">
				<template slot-scope="scope">
					{{ typeAry[scope.row.type] }}
				</template>
			</el-table-column>
            <el-table-column prop="businessId" label="页面ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="['/article/','/user/'][scope.row.type] + scope.row.businessId" target="_blank">{{ scope.row.businessId }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="createTime" label="分享时间" min-width="100" align="center"></el-table-column>
            
			<el-table-column prop="mode" label="分享方式" min-width="100" align="center">
                <template slot-scope="scope">
					{{ modeAry[scope.row.mode] }}
				</template>
            </el-table-column>
			<el-table-column prop="shareTo" label="分享至" min-width="100" align="center">
                <template slot-scope="scope">
					{{ shareToAry[scope.row.shareTo] }}
				</template>
            </el-table-column>
			<el-table-column prop="visitNumber" label="访问量" min-width="100" align="center"></el-table-column>
			<el-table-column prop="clickNumber" label="点击量" min-width="100" align="center"></el-table-column>
			<el-table-column prop="downloadNumber" label="安装量" min-width="100" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="toDetail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background  layout="slot, total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                <span class="page-slot">访问量: {{ visitNumber }}</span>
                <span class="page-slot">点击量: {{ clickNumber }}</span>
                <span class="page-slot">安装量: {{ downloadNumber }}</span>
			</el-pagination>
		</div>

		
    </div>
</template>

<script>
import {
    shareRecordPage,
	shareRecordStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				// nickName: '',
				userNumber: '',
				type: '',
                businessId: '',
                shareTo: '',
            },
			typeAry: ['动态','主页','app'],
            shareToAry: ['微信好友','微信朋友圈','QQ好友','QQ空间','保存本地'],
            modeAry: ['分享链接','分享海报'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			clickNumber: 0,
			downloadNumber: 0,
			visitNumber: 0,

			searchDate: [],
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getShareList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.searchDate = []

			this.page = 1
			this.getShareList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getShareList()
		},
		changeClick(page) {
			this.page = page
			this.getShareList()
		},
		getSearchParame() {
			return {
				"current": this.page,
				"size": this.size,

				// "nickName": this.query.nickName,
				"userNumber": this.query.userNumber,
				"type": this.query.type,
                "businessId": this.query.businessId,
                "shareTo": this.query.shareTo,
				"shareStartTime": this.searchDate != null ? this.searchDate[0] : null,
				"shareEndTime": this.searchDate != null ? this.searchDate[1] : null,
			}
		},
		//获取用户分享列表接口
		getShareList() {
            const params = this.getSearchParame()

			shareRecordPage(params)
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})

			shareRecordStatistics(params).then((response) => {
				console.log(response,'response')
				this.clickNumber = response.clickNumber
				this.downloadNumber = response.downloadNumber
				this.visitNumber = response.visitNumber
			})
		},
        exportData() {
            
        }
	},
	created() {
		//获取用户分享列表
		this.getShareList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.page-slot {
	font-weight: lighter;
	margin-right: 20px;
}
</style>