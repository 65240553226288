<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="开户名">
                    <el-input v-model="query.realName" placeholder="请输入开户名"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号">
                    <el-input v-model="query.bankCard" placeholder="请输入银行卡号"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待审核" value="0"></el-option>
						<el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="申请提现金额" min-width="80" align="center">
                <template slot-scope="scope">
                    <span style="text-decoration: line-through;">{{scope.row.money}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="handMoney" label="手续费" min-width="80" align="center"></el-table-column>
            <el-table-column prop="realMoney" label="实际提现金额" min-width="80" align="center">
                <template slot-scope="scope">
					<span style="color: #F56C6C">{{scope.row.realMoney}}元</span>
				</template>
            </el-table-column>
            <el-table-column prop="realName" label="开户名" min-width="80" align="center"></el-table-column>
            <el-table-column prop="bankName" label="开户行" min-width="120" align="center"></el-table-column>
            <el-table-column prop="bankCard" label="银行卡号" min-width="150" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="70" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">审核通过</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">审核拒绝</span>
				</template>
			</el-table-column>
            <el-table-column prop="updateTime" label="最后状态时间" min-width="140" align="center"></el-table-column>
            <!-- <el-table-column prop="createTime" label="创建时间" min-width="130" align="center"></el-table-column> -->
            <el-table-column prop="remark" label="备注" min-width="200" align="center"></el-table-column>
            <el-table-column label="电子回单" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" :src="'https://file.oiki.cc/' + scope.row.receiptUrl" :preview-src-list="['https://file.oiki.cc/' + scope.row.receiptUrl]" v-if="scope.row.receiptUrl"></el-image>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="310" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status === 0" type="warning" size="mini" icon="el-icon-edit" @click="agreeClick(scope.row.id)">同意</el-button>
                    <el-button v-if="scope.row.status === 0" type="danger" size="mini" icon="el-icon-delete" @click="refuseClick(scope.row.id)">拒绝</el-button>
                    <el-button v-if="scope.row.status == 1 && !scope.row.receiptUrl" type="success" size="mini" @click="uploadReceipt(scope.row.id)">上传电子回单</el-button>
                    <el-button type="info" size="mini" icon="el-icon-document" @click="viewClick(scope.row)">查看收益记录</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog :visible.sync="recordVisible" :close-on-click-modal="false" title="查看收益记录">
            <div style="text-align:left; vertical-align:middle;">
                <el-form :inline="true" :model="recordQuery" class="demo-form-inline" size="mini">
                    <el-form-item label="时间">
                        <el-date-picker v-model="recordSelectDate" type="daterange" align="right" unlink-panels range-separator="至"
                            start-placeholder="请选择到账起始时间" end-placeholder="请选择到账截止时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="recordQuery.type" placeholder="请选择类型" style="width: 130px;">
                            <el-option label="全部" :value="99"></el-option>
                            <el-option label="征友" :value="4"></el-option>
                            <el-option label="申请好友" :value="2"></el-option>
                            <el-option label="动态打赏" :value="0"></el-option>
                            <el-option label="聊天礼物" :value="1"></el-option>
                            <el-option label="动态激励-动态" :value="5"></el-option>
                            <el-option label="动态激励-评论" :value="6"></el-option>
                            <el-option label="动态激励-回复" :value="7"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="recordSearch">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table height="54vh" :data="recordList" border :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="type" label="类型" min-width="100" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type=== 0">动态打赏</span>
                        <span v-else-if="scope.row.type=== 1">聊天礼物</span>
                        <span v-else-if="scope.row.type=== 2">申请好友</span>
                        <span v-else-if="scope.row.type=== 3">提现</span>
                        <span v-else-if="scope.row.type=== 4">征友</span>
                        <span v-else-if="scope.row.type=== 5">动态激励-动态</span>
                        <span v-else-if="scope.row.type=== 6">动态激励-评论</span>
                        <span v-else-if="scope.row.type=== 7">动态激励-回复</span>

                        <!-- <el-popover
                            placement="right"
                            trigger="click"
                            @show="getBalanceDetail(scope.row)"
                            v-if="[0,1,2,4].includes(scope.row.type)"
                        >
                            asdadas
                            <i class="el-icon-search" style="cursor: pointer;color: #409eff;margin-left: 5px;" slot="reference"></i>
                        </el-popover> -->
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="来自" min-width="140" align="center">
                    <template slot-scope="scope" v-if="scope.row.giveUserNumber">
                        {{ scope.row.giveNickName }}
                        (<a :href="'/user/' + scope.row.giveUserId" target="_blank">{{ scope.row.giveUserNumber }}</a>)
                    </template>
                </el-table-column>
                <el-table-column prop="money" label="金额" min-width="60" align="center"></el-table-column>
                <el-table-column prop="createTime" label="到账时间" min-width="120" align="center"></el-table-column>
            </el-table>
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="recordSize" :total="recordTotal" :current-page="recordPage" @current-change="changeRecordClick">
            </el-pagination>
        </el-dialog>

        <!-- el-upload仅作为上传图片的控件，不进行组件内容展示 -->
        <el-upload
            ref="upload"
            action="api/file/upload"
            accept=".jpg,.jpeg,.png,.pneg,.Jpg,.Jpeg,.Png,.Pneg,"
            :limit="1"
            :headers="headers"
            :data="{code: 9002, index: 0}"
            :show-file-list="false"
            :on-success="uploadSuccess"></el-upload>
    </div>
</template>

<script>
import {
    takeMoneyPage,
    takeMoneyUpload,
    takeMoneyVerify,
    balancePage,
    balanceDetail
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            selectDate: [],
            query: {
                nickName: "",
                userNumber: "",
                realName: "",
                bankCard: "",
                status: ""
            },
            formLabelWidth: "80px",

            recordVisible: false,
            recordUserId: undefined,
            recordQuery: {
                type: 99
            },
            recordPage: 1,
            recordSize: 10,
            recordTotal: 0,
            recordList: [],
            recordSelectDate: [],

            receiptId: null,
            headers: {
                Authorization: "Bearer " + localStorage.token
            },

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
        };
    },
    methods: {
        search() {
            this.page = 1;
            this.getTakeMoneyList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getTakeMoneyList();
        },
        //获取待审核动态分页列表
        getTakeMoneyList() {
            takeMoneyPage({
                current: this.page,
                size: this.size,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                realName: this.query.realName,
                bankCard: this.query.bankCard,
                startDate: this.selectDate != null ? this.selectDate[0] : null,
				endDate: this.selectDate != null ? this.selectDate[1] : null,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
        agreeClick(id) {
            takeMoneyVerify({
                "id": id,
                "status": 1
            }).then(response => {
                this.getTakeMoneyList();
            })
        },
        refuseClick(id) {
            ('拒绝提现', {
                confirmButtonText: '拒绝',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入拒绝理由',
                center: true,
                distinguishCancelAndClose: true,
                closeOnClickModal: false
            }).then(({ value }) => {
                if (value == null) {
                    this.$message.error("请输入拒绝理由");
                } else {
                    //点击拒绝按钮
                    takeMoneyVerify({
                        "id": id,
                        "status": 2,
                        "remark": value
                    }).then(response => {
                        this.getTakeMoneyList();
                    })
                }
            }).catch(() => {});
        },
        uploadReceipt(id) {
            this.receiptId = id
            this.chooseImg()
        },
        // 选择文件
        chooseImg() {
            if (!this.receiptId) return;

            const uploadDom = this.$refs.upload
            const inputDom = this.$refs.upload.$refs['upload-inner'].$refs.input
            inputDom.click()
        },
        // 文件上传成功
        uploadSuccess(response, file) {
            const img = response.data
            takeMoneyUpload({id: this.receiptId, url: img}).then(res => {
                this.getTakeMoneyList()
            })

            // 上传成功等待渲染变化之后清除组件中记录的文件列表
            this.$nextTick(() => {
                this.receiptId = null
                this.$refs.upload.clearFiles()
            })
        },
        // 导出列表数据
		exportData() {
			('请输入导出数据条数(默认100)','导出数据').then(({value}) => {
				let number = value != null ? parseInt(value) : 100
				if (isNaN(number)) {
					this.$message.error('请输入整型数值！')
					return;
				} else if (number > 2147483647) {
					this.$message.info('数值超过int最大长度，已变更为2147483647！')
					number = 2147483647
				}

				const exportUrl = '/api/takeMoney/export'

				const params = {
					current: 1,
					size: number,
                    status: this.query.status,
                    nickName: this.query.nickName,
                    userNumber: this.query.userNumber,
                    realName: this.query.realName,
                    bankCard: this.query.bankCard,
                    startDate: this.selectDate != null ? this.selectDate[0] : null,
                    endDate: this.selectDate != null ? this.selectDate[1] : null,
				}

				let urlParams = '?'
				Object.keys(params).forEach(key => {
					const value = params[key]
					if (value) {
						urlParams += '&' + key + '=' + encodeURIComponent(value)
					}
				})

				console.log(urlParams,'urlParams')
				const fileName = '提现列表.xlsx'
				const xhr = new XMLHttpRequest()
				xhr.open('GET', exportUrl + urlParams, true)
				xhr.responseType = 'blob'
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token)
				xhr.onload = () => {
					if (xhr.status == 200) {
						const type = xhr.getResponseHeader('Content-Type')
						const blob = new Blob([xhr.response], {type})
						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(blob, fileName)
						} else {
							const URL = window.URL || window.webkitURL
							const blobUrl = URL.createObjectURL(blob)

							const a = document.createElement('a')
							if (typeof a.download === 'undefined') {
								window.open(blobUrl)
							} else {
								a.href = blobUrl
								a.download = fileName
								document.body.appendChild(a)
								a.click()
								a.remove()
							}
						}
					} else {
						this.$message.error('导出失败')
					}
				}
				xhr.send()
			})
			
		},
        viewClick(row) {
            this.recordUserId = row.userId
            this.getRecordPage()
        },
        getRecordPage() {
            balancePage({
                userId: this.recordUserId,
                current: this.recordPage,
                size: this.recordSize,
                status: 3,
                type: this.recordQuery.type,
                startTime: this.recordSelectDate != null ? this.recordSelectDate[0] : null,
				endTime: this.recordSelectDate != null ? this.recordSelectDate[1] : null,
            }).then(response => {
                this.recordVisible = true
                // console.log(response,'response')
                this.recordTotal = response.total;
                this.recordList = response.records;
            })
        },
        changeRecordClick(page) {
            this.recordPage = page
            this.getRecordPage()
        },
        recordSearch() {
            this.recordPage = 1
            this.getRecordPage()
        },
        getBalanceDetail(row) {
            balanceDetail(row.id).then(res => {
                console.log(res,'res')
            })
        }
    },
    mounted() {
        this.getTakeMoneyList();
    }
};
</script>