<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
				<el-form-item label="昵称">
					<el-input v-model="query.nickName" placeholder="昵称" style="width: 140px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 140px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="taskId">
					<el-input v-model="query.taskId" placeholder="taskId" style="width: 280px" @keydown.enter.native="search"></el-input>
				</el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="query.type" placeholder="请选择类型" style="width: 120px;">
                        <el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in types" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否实名">
                    <el-select v-model="query.isRealName" placeholder="请选择是否实名" style="width: 140px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已实名" value="1"></el-option>
                        <el-option label="未实名" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="创建时间">
					<el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="编号" min-width="100" align="center">
				<template slot-scope="scope">
					<a :href="`/user/${scope.row.userId}`" target="_blank">{{ scope.row.userNumber }}</a>
				</template>
			</el-table-column>
			<el-table-column prop="gender" label="性别" min-width="40" align="center">
				<template slot-scope="scope">
					{{ ['','男','女'][scope.row.gender] }}
				</template>
			</el-table-column>
			<el-table-column prop="isRealName" label="实名" min-width="40" align="center">
				<template slot-scope="scope">
					{{ scope.row.isRealName === 1 ? '是' : '否' }}
				</template>
			</el-table-column>
			<el-table-column prop="taskId" label="taskId" min-width="140" align="center"></el-table-column>
			<!-- <el-table-column prop="category" label="分类" min-width="40" align="center">
				<template slot-scope="scope">
					{{ categorys[scope.row.category] }}
				</template>
			</el-table-column> -->
			<el-table-column prop="type" label="类型" min-width="40" align="center">
				<template slot-scope="scope">
					{{ types[scope.row.type] }}
				</template>
			</el-table-column>
			<el-table-column prop="content" label="违规内容" min-width="140" align="center">
				<template slot-scope="scope">
					<div class="flex-center-box">
						<div v-if="scope.row.category == 1">
							<el-image
								style="height: 60px; width: 60px; padding: 2px;"
								v-for="(item, index) in scope.row.images"
								:key="index"
								:src="item"
								:preview-src-list="scope.row.images" 
							></el-image>
						</div>
						<div v-else-if="scope.row.category == 2">
							<audio controls>
                                <source :src="scope.row.audio">
                            </audio>
						</div>
						<div v-else-if="scope.row.category == 3">
							<video muted="muted" class="video" :src="scope.row.video" type="video/mp4" :poster="scope.row.video" autoplay="autoplay" style="width: 150px; height: 150px; padding-top: 10px;" controls="controls" loop="-1">
								<p>你的浏览器不支持video标签.</p>
							</video>
						</div>
						<div v-else>
							{{ scope.row.content }}
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>

			<el-table-column fixed="right" label="操作" min-width="60" align="center">
				<template slot-scope="scope">
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>
    </div>
</template>

<script>
import { userFoulPage, userFoulDelete } from '@/api/api'
import setTableHeight from '@/utils/setTableHeight'
import { montageDomain } from '@/utils/'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,

            searchDate: [],

			categorys: ['文本', '图片', '音频', '视频'],
			types: ['动态', '昵称', '签名', '评论', '回复', '征友', '头像', '相册', '语音介绍', '临时聊天'],

            query: {
                nickName: '',
				userNumber: '',
				taskId: '',
				type: '',
				isRealName: '',
			},

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getUserList();
		},
        handleSizeChange(size) {
			this.size = size
			this.getUserList()
		},
		changeClick(page) {
			this.page = page
			this.getUserList()
		},
        getUserList() {
			const params = {
				current: this.page,
				size: this.size,
				nickName: this.query.nickName,
				userNumber: this.query.userNumber,
				taskId: this.query.taskId,
				type: this.query.type,
				isRealName: this.query.isRealName,
                startTime: this.searchDate != null ? this.searchDate[0] : null,
				endTime: this.searchDate != null ? this.searchDate[1] : null,
			}

            userFoulPage(params).then(response => {
				const fileDomain = 'https://file.oiki.cc/'
                
				this.total = response.total
				this.tableData = response.records.map(item => {
					if (item.category == 1) {
						const images = item.content.split(',')
						item.images = images.map(img => montageDomain(img, fileDomain))
					} else if ([2,3].includes(item.category)) {
						const fields = {2: 'audio', 3: 'video'}
						item[fields[item.category]] = montageDomain(item.content, fileDomain)
					}

					return item;
				})

                console.log(this.tableData,'tableData')
			})
        },
		deleteById(id) {
			this.$confirm('确定要删除此记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
				params.append("idList", [id])
				userFoulDelete({ params }).then(() => {
					this.getUserList() //删除成功，重新获取数据
				})
			})
		},
    },
    created() {
		//获取用户列表
		this.getUserList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.flex-center-box {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>