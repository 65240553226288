<template>
    <div>
		<div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" class="demo-form-inline">
				<!-- <el-form-item label="昵称">
					<el-input v-model="query.nickName" placeholder="昵称" style="width: 140px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 140px" @keydown.enter.native="search"></el-input>
				</el-form-item> -->
				<el-form-item>
					<!-- <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button> -->
					<el-button type="success" @click="showForm()">添加</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="sort" label="排序" min-width="60" align="center"></el-table-column>
			<el-table-column prop="name" label="名称" min-width="60" align="center"></el-table-column>
			<el-table-column prop="url" label="表情" min-width="60" align="center">
				<template slot-scope="scope">
					<el-image
                        style="height: 60px; width: 60px; padding: 2px;"
                        :src="fileDomain + scope.row.url"
                        :preview-src-list="[fileDomain + scope.row.url]"
                    ></el-image>
				</template>
			</el-table-column>
			
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>

			<el-table-column fixed="right" label="操作" min-width="60" align="center">
				<template slot-scope="scope">
					<el-button type="primary" size="mini" icon="el-icon-edit" @click="showForm(scope.row)">修改</el-button>
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteFace(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

		<el-dialog :visible.sync="faceFormVisible" :close-on-click-modal="false">
			<el-form ref="faceForm" :model="faceForm" label-width="80px" :rules="faceFormRules">
				<el-form-item label="表情名称" prop="name">
					<el-input v-model="faceForm.name" placeholder="请输入表情名称"></el-input>
				</el-form-item>
				<el-form-item label="表情图片" prop="url">
					<div class="upload-box" @click="chooseImg">
						<div class="upload-icon" v-if="!faceForm.url">
							<i class="el-icon-plus"></i>
						</div>
						<img :src="fileDomain + faceForm.url" alt="" v-else>
					</div>
					<div style="color: red;text-align: left;">图片尺寸不能小于: 256px × 256px</div>

					<!-- 图片上传组件（不进行展示） -->
					<el-upload
						ref="upload"
						action="api/file/emote"
						accept=".jpg,.jpeg,.png,.pneg,.gif,.Jpg,.Jpeg,.Png,.Pneg,.Gif"
						:limit="1"
						:headers="headers"
						:data="{code: 5001, index: 0}"
						:show-file-list="false"
						:on-success="uploadSuccess"
						v-show="0"
					></el-upload>
				</el-form-item>

				<el-form-item label="排序" prop="sort">
					<el-input v-model="faceForm.sort" type="number"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="faceFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="submitLoading" @click="submitFaceForm">确 定</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import { sysEmotePage, sysEmotePost, sysEmoteUpdate, sysEmoteDelete } from '@/api/api'
import setTableHeight from '@/utils/setTableHeight'
import { Image } from 'element-ui'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
			query: {},

			fileDomain: 'https://file.oiki.cc/',

			headers: {
                Authorization: "Bearer " + localStorage.token
            },

			faceFormVisible: false,
			faceForm: {},
			faceFormRules: {
				name: [
					{ required: true, message: '请输入表情名称' }
				],
				url: [
					{ required: true, message: '请上传表情图片' }
				],
			},
			submitLoading: false
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			this.page = 1;
			this.getFaceList();
		},
        handleSizeChange(size) {
			this.size = size
			this.getFaceList()
		},
		changeClick(page) {
			this.page = page
			this.getFaceList()
		},
		getFaceList() {
			
			const params = {
				current: this.page,
				size: this.size,
			}

            sysEmotePage(params).then(response => {
				this.total = response.total
				this.tableData = response.records
			})
		},
		showForm(formInfo) {
			const fields = ['id', 'name','url','sort']

			for(const field of fields) {
				const value = formInfo ? formInfo[field] : ''
				this.$set(this.faceForm, field, value)
			}

			this.faceFormVisible = true
		},
		// 选择文件
        chooseImg() {
            const uploadDom = this.$refs.upload
            const inputDom = this.$refs.upload.$refs['upload-inner'].$refs.input
            inputDom.click()
        },
		// 文件上传成功
        uploadSuccess(response, file) {
            const img = response.data
			this.faceForm.url = img
            
            // 上传成功等待渲染变化之后清除组件中记录的文件列表
            this.$nextTick(() => {
                this.$refs.upload.clearFiles()
            })
        },
		async submitFaceForm() {
			await new Promise((resolve, reject) => {
				this.$refs.faceForm.validate(valid => {
					if (valid) {
						resolve()
					}
				})
			})

			let fn;
			if (this.faceForm.id) {
				fn = sysEmoteUpdate
			} else {
				fn = sysEmotePost
			}

			this.submitLoading = true
			fn(this.faceForm).then(res => {
				this.getFaceList()
				this.faceFormVisible = false
				this.submitLoading = false
			}).catch(() => {
				this.submitLoading = false
			})
		},
		deleteFace(id) {
			this.$confirm('确定要删除此表情?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
				params.append("idList", [id])
				sysEmoteDelete({ params }).then(() => {
					this.getFaceList()
				})
			})
		}
    },
    created() {
		//获取表情列表
		this.getFaceList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.upload-box {
	width: 80px;
	height: 80px;
	border: 1px dashed #ddd;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	overflow: hidden
}

.upload-box img {
	width: 100%;
	height: 100%;
}

.upload-icon {
	font-size: 38px;
}
</style>